	  @font-face {
	  	font-family: 'CenturyGothic';
	  	src: url('../fonts/CenturyGothic.eot');
	  	src: local('☺'), url('../fonts/CenturyGothic.woff') format('woff'), url('../fonts/CenturyGothic.ttf') format('truetype'), url('../fonts/CenturyGothic.svg') format('svg');
	  	font-weight: normal;
	  	font-style: normal;
	  }
	  
	  @font-face {
	  	font-family: 'CenturyGothic';
	  	src: url('../fonts/CenturyGothicBold.eot');
	  	src: local('☺'), url('../fonts/CenturyGothicBold.woff') format('woff'), url('../fonts/CenturyGothicBold.ttf') format('truetype'), url('../fonts/CenturyGothicBold.svg') format('svg');
	  	font-weight: bold;
	  	font-style: normal;
	  }
	  
	  	  @font-face {
	  	font-family: 'CenturyGothic';
	  	src: url('../fonts/CenturyGothicItalic.eot');
	  	src: local('☺'), url('../fonts/CenturyGothicItalic.woff') format('woff'), url('../fonts/CenturyGothicItalic.ttf') format('truetype'), url('../fonts/CenturyGothicItalic.svg') format('svg');
	  	font-weight: normal;
	  	font-style: italic;
	  }
	  
	  @font-face {
	  	font-family: 'CenturyGothic';
	  	src: url('../fonts/CenturyGothicBoldItalic.eot');
	  	src: local('☺'), url('../fonts/CenturyGothicBoldItalic.woff') format('woff'), url('../fonts/CenturyGothicBoldItalic.ttf') format('truetype'), url('../fonts/CenturyGothicBoldItalic.svg') format('svg');
	  	font-weight: bold;
	  	font-style: italic;
	  }
	  
$space-height:   400px !default;
$space-md:   400px !default;
$space-sm:   400px !default;
$space-xs:   400px !default;

	   	
	  
 .he{
background: url(../images/tile.jpg) repeat;
	background-size: 164px 164px;
	height: 500px;
 }
  
 .heading2{
 	background: linear-gradient(to bottom, rgba(56, 56, 56, 1) 20%, rgba(56, 56, 56, 0.9) 50%, rgba(56, 56, 56, 1) 80%);
 	height: 500px;
 	width: 100%;
  position: absolute;
  top: 0;
  left: 0;
 }
 
 .con{
	 	height: 500px;
 
 }
 
 .aligner {
  display: flex;
  align-items: center;
  justify-content: center;
	 	height: 500px;
}
 .aligner2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

 .aligner3 {
  display: flex;
  align-items: center;
  justify-content: center;
	height:$space-height;
}

.log{
	padding:0 20px;
}

.nopad{
	padding:0;
	}
	
	
.spacesfluid{
text-align:center;
border-top:2px solid #fff;

@media(max-width:767px){}
@media(min-width:768px){	
	height:$space-height;
position:relative;
}
@media(min-width:992px){}
@media(min-width:1200px){}
}

.description{
	background: url(../images/tile.jpg) repeat;
	background-size: 164px 164px;
	padding:0;
@media(max-width:767px){}
@media(min-width:768px){	
	height:$space-height;
position:relative;
}
@media(min-width:992px){}
@media(min-width:1200px){}

}

.description .aligner3{
		 background:#fff;
 	background: linear-gradient(to bottom, rgba(255, 255, 255, 0.95) 10%, rgba(255, 255, 255, 0.85) 50%, rgba(255, 255, 255, 0.95) 90%);
 	width: 100%;
@media(max-width:767px){}
@media(min-width:768px){
	height:$space-height;
  position: absolute;
  top: 0;
  left: 0;
}
@media(min-width:992px){}
@media(min-width:1200px){}

}

  
 .spacesfluid2{
	 background:#fff;
 	background: linear-gradient(to bottom, rgba(255, 255, 255, 0.95) 10%, rgba(255, 255, 255, 0.85) 50%, rgba(255, 255, 255, 0.95) 90%);
 	width: 100%;
@media(max-width:767px){}
@media(min-width:768px){
	height:$space-height;
  position: absolute;
  top: 0;
  left: 0;
}
@media(min-width:992px){}
@media(min-width:1200px){}
}

.footer{
	text-align:center;
	padding:20px 0;
	color:#fff;
}
.foo{
		background:#404041;
border-top:2px solid #fff;
z-index: 11;
}
	
#hazelton-img{
background: url(../images/hazelton-img.jpg) no-repeat center center; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
   	height:$space-height;
}

#parkview-img{
	background:url(../images/parkview-img.jpg) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
	height:$space-height;
}

#forth-img{
	background:url(../images/forth-img.jpg) no-repeat center center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
	height:$space-height;
}